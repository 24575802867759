"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var recaptcha_v3_service_1 = require("./recaptcha-v3.service");
var RecaptchaV3Module = /** @class */ (function () {
    function RecaptchaV3Module() {
    }
    RecaptchaV3Module.decorators = [
        { type: core_1.NgModule, args: [{
                    providers: [
                        recaptcha_v3_service_1.ReCaptchaV3Service,
                    ],
                },] },
    ];
    return RecaptchaV3Module;
}());
exports.RecaptchaV3Module = RecaptchaV3Module;
