"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var recaptcha_component_1 = require("./recaptcha.component");
var RecaptchaCommonModule = /** @class */ (function () {
    function RecaptchaCommonModule() {
    }
    RecaptchaCommonModule.decorators = [
        { type: core_1.NgModule, args: [{
                    declarations: [
                        recaptcha_component_1.RecaptchaComponent,
                    ],
                    exports: [
                        recaptcha_component_1.RecaptchaComponent,
                    ],
                },] },
    ];
    return RecaptchaCommonModule;
}());
exports.RecaptchaCommonModule = RecaptchaCommonModule;
