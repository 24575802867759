"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var recaptcha_loader_service_1 = require("./recaptcha-loader.service");
exports.RECAPTCHA_V3_SITE_KEY = new core_1.InjectionToken('recaptcha-v3-site-key');
/**
 * The main service for working with reCAPTCHA v3 APIs.
 *
 * Use the `execute` method for executing a single action, and
 * `onExecute` observable for listening to all actions at once.
 */
var ReCaptchaV3Service = /** @class */ (function () {
    function ReCaptchaV3Service(zone, siteKey, 
    // tslint:disable-next-line:no-any
    platformId, baseUrl, nonce) {
        var _this = this;
        /** @internal */
        this.onLoadComplete = function (grecaptcha) {
            _this.grecaptcha = grecaptcha;
            if (_this.actionBacklog && _this.actionBacklog.length > 0) {
                _this.actionBacklog.forEach(function (_a) {
                    var action = _a[0], subject = _a[1];
                    return _this.executeActionWithSubject(action, subject);
                });
                _this.actionBacklog = undefined;
            }
        };
        this.zone = zone;
        this.isBrowser = common_1.isPlatformBrowser(platformId);
        this.siteKey = siteKey;
        this.nonce = nonce;
        this.baseUrl = baseUrl;
        this.init();
    }
    Object.defineProperty(ReCaptchaV3Service.prototype, "onExecute", {
        get: function () {
            if (!this.onExecuteSubject) {
                this.onExecuteSubject = new rxjs_1.Subject();
                this.onExecuteObservable = this.onExecuteSubject.asObservable();
            }
            return this.onExecuteObservable;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Executes the provided `action` with reCAPTCHA v3 API.
     * Use the emitted token value for verification purposes on the backend.
     *
     * For more information about reCAPTCHA v3 actions and tokens refer to the official documentation at
     * https://developers.google.com/recaptcha/docs/v3.
     *
     * @param {string} action the action to execute
     * @returns {Observable<string>} an `Observable` that will emit the reCAPTCHA v3 string `token` value whenever ready.
     * The returned `Observable` completes immediately after emitting a value.
     */
    /**
       * Executes the provided `action` with reCAPTCHA v3 API.
       * Use the emitted token value for verification purposes on the backend.
       *
       * For more information about reCAPTCHA v3 actions and tokens refer to the official documentation at
       * https://developers.google.com/recaptcha/docs/v3.
       *
       * @param {string} action the action to execute
       * @returns {Observable<string>} an `Observable` that will emit the reCAPTCHA v3 string `token` value whenever ready.
       * The returned `Observable` completes immediately after emitting a value.
       */
    ReCaptchaV3Service.prototype.execute = /**
       * Executes the provided `action` with reCAPTCHA v3 API.
       * Use the emitted token value for verification purposes on the backend.
       *
       * For more information about reCAPTCHA v3 actions and tokens refer to the official documentation at
       * https://developers.google.com/recaptcha/docs/v3.
       *
       * @param {string} action the action to execute
       * @returns {Observable<string>} an `Observable` that will emit the reCAPTCHA v3 string `token` value whenever ready.
       * The returned `Observable` completes immediately after emitting a value.
       */
    function (action) {
        var subject = new rxjs_1.Subject();
        if (this.isBrowser) {
            if (!this.grecaptcha) {
                // todo: add to array of later executions
                if (!this.actionBacklog) {
                    this.actionBacklog = [];
                }
                this.actionBacklog.push([action, subject]);
            }
            else {
                this.executeActionWithSubject(action, subject);
            }
        }
        return subject.asObservable();
    };
    /** @internal */
    /** @internal */
    ReCaptchaV3Service.prototype.executeActionWithSubject = /** @internal */
    function (action, subject) {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            // tslint:disable-next-line:no-any
            // tslint:disable-next-line:no-any
            _this.grecaptcha.execute(_this.siteKey, { action: action }).then(function (token) {
                _this.zone.run(function () {
                    subject.next(token);
                    subject.complete();
                    if (_this.onExecuteSubject) {
                        _this.onExecuteSubject.next({ action: action, token: token });
                    }
                });
            });
        });
    };
    /** @internal */
    /** @internal */
    ReCaptchaV3Service.prototype.init = /** @internal */
    function () {
        if (this.isBrowser) {
            if ('grecaptcha' in window) {
                this.grecaptcha = grecaptcha;
            }
            else {
                recaptcha_loader_service_1.loadScript(this.siteKey, this.onLoadComplete, '', this.baseUrl, this.nonce);
            }
        }
    };
    ReCaptchaV3Service.decorators = [
        { type: core_1.Injectable },
    ];
    /** @nocollapse */
    ReCaptchaV3Service.ctorParameters = function () { return [
        { type: core_1.NgZone, },
        { type: undefined, decorators: [{ type: core_1.Inject, args: [exports.RECAPTCHA_V3_SITE_KEY,] },] },
        { type: undefined, decorators: [{ type: core_1.Inject, args: [core_1.PLATFORM_ID,] },] },
        { type: undefined, decorators: [{ type: core_1.Optional }, { type: core_1.Inject, args: [recaptcha_loader_service_1.RECAPTCHA_BASE_URL,] },] },
        { type: undefined, decorators: [{ type: core_1.Optional }, { type: core_1.Inject, args: [recaptcha_loader_service_1.RECAPTCHA_NONCE,] },] },
    ]; };
    return ReCaptchaV3Service;
}());
exports.ReCaptchaV3Service = ReCaptchaV3Service;
