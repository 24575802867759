"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var recaptcha_common_module_1 = require("./recaptcha-common.module");
var recaptcha_loader_service_1 = require("./recaptcha-loader.service");
var recaptcha_component_1 = require("./recaptcha.component");
var RecaptchaModule = /** @class */ (function () {
    function RecaptchaModule() {
    }
    // We need this to maintain backwards-compatibility with v4. Removing this will be a breaking change
    // We need this to maintain backwards-compatibility with v4. Removing this will be a breaking change
    RecaptchaModule.forRoot = 
    // We need this to maintain backwards-compatibility with v4. Removing this will be a breaking change
    function () {
        return RecaptchaModule;
    };
    RecaptchaModule.decorators = [
        { type: core_1.NgModule, args: [{
                    exports: [
                        recaptcha_component_1.RecaptchaComponent,
                    ],
                    imports: [
                        recaptcha_common_module_1.RecaptchaCommonModule,
                    ],
                    providers: [
                        recaptcha_loader_service_1.RecaptchaLoaderService,
                    ],
                },] },
    ];
    return RecaptchaModule;
}());
exports.RecaptchaModule = RecaptchaModule;
